.nav {
  max-width: 1600px;  
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  width: 100%;
}

.nav__link {
  text-decoration: none;
  background: none;
  border: none;
  color: white;
  text-shadow: 0 0 15px #EC262A;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
}

.menu1 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}

.menu1 li {
  display: block;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  text-align: center;
  margin-right: 30px;
}

.menu1 li:after,
.menu1 li:before {
  content: "";
  position: absolute;
  display: block;
  border: 0px solid transparent;
  width: 0%;
  height: 0%;
  transition: all 0.3s ease;
}

.menu1 li:after {
  width: 0%;
  height: 0%;
  top: 0;
  left: 0;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
}

.menu1 li:before {
  width: 0%;
  height: 0%;
  right: 0;
  bottom: 0;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
}

.menu1 li:hover::before,
.menu1 li:hover::after {
  width: 10px;
  height: 10px;
  border-color: #fff;
}

.hide {
  display: none;
}

.hidden__menu {
  display: none;
  background: none;
  border: none;
  color: #EC262A;
  cursor: pointer;
  font-size: 25px;
  margin:16px;
  transition-duration: 1s;
}

.hidden__menu:hover {
  transform: scale(1.2);
}

.hidden__menu:active {
  transform: scale(1);
}


@media(max-width: 706px) {
  .nav {
    width: 90%;
  }
  .menu1 {
    display: flex;
    justify-content: end;
  }

  .to-remove {
    display: none;
  }
}
