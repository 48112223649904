.about {
  color: white;
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 54px 64px;
  width: 100%;
}

.about h1{
  padding-bottom: 24px;
}

.img {
  height: 100%;
  width: 100%;
  max-height: 500px;
}

.img__container {
  width: 50%;
}

.about__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__container--mission {
  display: flex;
  flex-direction: column;

}

.box{
  border-radius: 4px;
  width: 50%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.box--mission {
  display: flex;
  align-items: center;
}

.about p{
  font-size: 22px;
  line-height: 1.6;
}

.about__history {
  padding: 56px;
  margin: 80px 0;
}

@media(max-width: 1400px) {
  .about p {
    font-size: 20px;
  }

}

@media(max-width: 1100px) {
  .about p {
    font-size: 18px;
  }

  .about__container {
    flex-direction: column;
  }

  .box {
    width: 100%;
  }

  .img__container {
    width: 70%;
  }
}

@media(max-width: 800px) {
  .box--mission {
    flex-direction: column;
  }
}