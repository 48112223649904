.tech {
  text-align: center;
  color: white;
  max-width: 1600px;
  margin: 0 auto;
}

.lists {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid #EC262A;
  margin: 112px;
}

.list {
  display: flex;
  align-items: center;
  color: white;
  cursor: default;
  padding: 32px 0;
  width: 90%;
}

.list h2{
  font-size: 30px;
  width: 175px;
}

.designs {
  display: flex;
  align-items: center;
}

.list__items {
  display: flex;
  flex-wrap: wrap;
}

.line {
  border: #EC262A 1px solid;
  width: 50px;
}

@media(max-width: 706px) {
  .lists{
    margin: 32px;
  }
  
  .list h2{
    font-size: 25px;
    width: 150px;
  }

  .line {
    width: 25px;
  }
}
