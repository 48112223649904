.projects {
  text-align: center;
  color: white;
}

.project__list {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  margin: 0 128px;
}

@media(max-width: 706px) {
  .projects__list {
    margin: 0;
  }
}