.tech__box {
  background-color: #554d4d;
  color: white;
  padding: 8px 16px;
  margin: 16px;
  cursor: default;
  border-radius: 4px;
  transition: all ease-in-out .25s;
  width: 170px;
}

.tech__box p{
  font-size: 25px;
}

.tech__box:hover {
  transform: scale(1.2);
  background-color: #eC262A;
}

@media(max-width: 706px) {
  .tech__box{
    width: 150px;
  }
  .tech__box p{
    font-size: 20px;
  }
}