.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #e9e9e9;
  z-index: 2;
}

.footer__container {
  padding: 25px;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer__logo{ 
  border:none;
  background: none;
  margin-bottom: 8px;
}

.footer__p {
  font-size: 20px;
  margin-bottom: 8px;
}

.footer--icons {
  display: flex;
  padding: 16px;
  color: #EC262A;
  font-size: 24px;
}

.footer__line {
  border: 1px solid #2c2828;
  width: 100%;
}

.footer__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo {
  color:#e9e9e9;
  text-align: center;
  padding-bottom: 8px;
}