* {
  font-family: 'Arial', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #2c2828;
}

.red{
  color:red;
}

.logo {
  max-height: 50px;
  filter: invert(100%)
}

.link {
  text-decoration: none;
  color: black;
}

.new__section {
  margin-top: 80px;
}

.title {
  font-size: 48px;
}