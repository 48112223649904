.contacts{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
}

.links__box{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #EC262A;
  padding: 0 8px;
}

.long-line {
  border: 2px solid #EC262A;
  height: 100%;
}

.links {
  font-size: 30px;
  color: white;
  margin: 8px 0;
}

.links:hover {
  transform: scale(1.2); 
}

.road {
  position: fixed;
  bottom: 0;
  left: 0%;
  transform: translate(5%, 0%);
  display: flex;
  align-items: flex-end;
  height: 25%;
}

.imgs__container {
  display: flex;
}

.imgs {
  width: 100px;
  height: 50px;
  margin-right: 8px;
}

.hide {
  display: none;
}

.bike {
  filter: invert(100%);
  rotate: y 180deg;
}

@media(max-width: 1100px) {
  .imgs{
    display: none;
  }
}

@media(max-width: 706px) {
  .contacts{
    display: none;
  }
}