.landing {
  color: white;
  height: 87vh;
  max-width: 1800px;
  margin: 0 auto;
}

.landing__text {
  margin-right: 32px;
  position: relative;
  top: 25%;
  left: 50%;
  transform: translate(-45%, 25%);
}

.light {
  background: radial-gradient(circle at 0 300px, #ffffff7a 2%,#ffffff34 5%, transparent 15%);
}

.landing__text h1 {
  font-size: 60px;
}

.landing__text h2 {
  font-size: 30px;
  margin: 4px 80px 16px 0;
}

.landing__button {
  cursor: pointer;
  font-size: 30px;
  background-color: #ffffff;
  position: relative;
  padding: 8px 16px;
  border-top-right-radius:10px;
  border-bottom-left-radius:10px;
  transition:all 1s;
  &:after,&:before{
    content:" ";
    width:10px;
    height:10px;
    position:absolute;
    transition:all 0.75s;
  }
  &:after{
    top:-1px;
    left:-1px;
    border-top:5px solid #EC262A;
    border-left:5px solid #EC262A;
  }
  &:before{
    bottom:-1px;
    right:-1px;
    border-bottom:5px solid #EC262A;
    border-right:5px solid #EC262A;
  }
  &:hover{
    border-top-right-radius:0px;
    border-bottom-left-radius:0px;
    &:before,&:after{
      width:100%;
      height:100%;
    }
  }
}

.arrow__container {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.arrow {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 25px;
}

.arrow:hover {
  animation: arrowMove 1s infinite;
}

@keyframes arrowMove {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}



@media(max-width: 706px) {
  .landing__text h1 {
    font-size: 40px;
  }
  
  .landing__text h2 {
    font-size: 20px;
  }

  .landing__button {
    font-size: 20px;
  }
}

@media(max-width: 450px) {
  .landing__button {
    font-size: 15px;
  }
}

