.project {
  width: 100%;
  display: flex;
  cursor: default;
}

.project__desc {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  text-align: left;
}

.project__desc h1{
  font-size: 35px;
  text-decoration: underline;
  margin-bottom: 16px;
}

.project__desc h3{
  margin: 8px 0;
  font-size: 27px;
}

.project__desc p{
  font-size: 23px;
}
  
.project {
  margin: 48px 0;
  text-decoration: none;
  color: white;
}

.project:hover {
  transform: scale(1.05);
  transition: all ease-in-out .25s;
}

.image__container {
  width: 30%;
  height: 300px;
  
}

.image {
  width:100%;
  height: 100%;
  border-radius: 8px;
  object-fit: fill;
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 16px;
  font-size: 20px;
}

.btn-one {
  color: #FFF;
  transition: all 0.3s;
  position: relative;
}
.btn-one span {
  transition: all 0.3s;
}
.btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #EC262A;
  border-bottom-color: #EC262A;
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1; 
  transform: scale(1, 1); 
}
.btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255,255,255,0.1);
}
.btn-one:hover::after {
  opacity: 0; 
  transform: scale(0.1, 1);
}

@media(max-width: 1100px) {
  .project__desc h1{
    font-size: 30px;
  }

  .project__desc h3{
    font-size: 23px;
  }

  .project__desc p{
    font-size: 20px;
  }
  
}

@media(max-width: 830px) {
  .project__desc h1{
    font-size: 27px;
  }

  .project__desc h3{
    font-size: 23px;
  }

  .project__desc p{
    font-size: 20px;
  }

  .btn{ 
    width: 150px;
  }
}

@media(max-width: 706px) {
  .project {
    flex-direction: column;
    align-items: center;
  }

  .image__container{
    width: 100%;
  }

  .project__desc {
    max-width: 100%;
    padding: 0;
    margin-top: 16px;
  }
}